.invoice-summary {
  margin: auto;

  .ant-typography {
    color: #000;
  }

  .package-info {
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 32px;

    .info-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      span {
        margin-left: 8px;
        flex: 1;
      }

      svg {
        color: #50d4d4;
      }
    }
  }

  .invoice-section {
    margin-bottom: 24px;

    .credit-line {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .heading {
      font-weight: 500;
      font-size: 14px;
      color: #000;
    }

    .label {
      font-size: 13px;
      font-weight: 400;
      color: #0000009d;
    }

    .value {
      font-size: 13px;
      font-weight: 600;
      color: #00000073;
    }

    .total-due {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      margin-bottom: 16px;
      .label {
        color: #000;
        font-size: 13px;
        font-weight: 400;
      }
      .value {
        color: #000;
        font-size: 14px;
        font-weight: 600;
      }
      .vat {
        color: #929292;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }

  .footer {
    text-align: center;

    .testimonial {
      margin: 32px 0;
      font-size: 14px;
      font-weight: 400px;
      .bold-text {
        font-weight: 600;
      }
    }

    .payment-logos {
      display: flex;
      justify-content: center;
      gap: 12px;

      img {
        height: 30px;
      }
    }
  }

  .ant-divider-horizontal {
    margin: 16px 0 !important;
  }

  .confirm-button {
    width: fit-content;
    border-radius: 5px;
    padding: 4px 15px;
    text-align: right;
    font-size: 14px;
  }
}
