/* 
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  Please don't add changes to this file, it will be automatically overwritten.
  The funnel is the source of truth. Changes need to be made there.
  #######################################################################################
  ####################################### WARNING #######################################
  #######################################################################################
  */
  .faq-container {
      display: flex;
      padding: 25px;

      .faq-content {
          padding-left: 12px;
          padding-right: 24px;
          min-width: 100% !important;
      }

      .faq-icon {
          display: flex;
          font-size: 30px;
          cursor: pointer;
      }

  }

  .add-new-choice-wrapper {
      border: 2px dashed #23d3d3;
      height: 100%;

      .add-icon-wrapper {
          background-color: #23d3d3;
          border-radius: 50%;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
              color: #fff;
              height: 18px;
              width: 18px;
          }
      }

      @include defaultOpacityHover;
  }
